<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Executive Compensation Survey (Private)
      </h2>

      <img
        src="../../assets/img/getty.jpg"
        alt="Executive Compensation Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >
        <p class="py-4">
          Executives play a crucial role in their organisation.
          They are the ones setting corporate strategies and making
          important decisions that affect the
          viability of their businesses. To keep them motivated and
          satisfied, it is important to provide them
          with the right remuneration package.
        </p>
        <br>
        <p class="py-4">
          With more than 100 participating multinational companies across Asia,
          MRC Executive Compensation Survey is a definitive source of market data for:
        </p>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>CEO and C Level Executives /Company Head, Function
            Head and selected Sub Function Head</li>
          <li>Local and/or Expat</li>
          <li>Basic Salary, Fixed Allowances, Short-term Incentives,
            Long-term Incentives and Perquisites</li>
          <li>APAC locations (19): Australia, India, China, Hong Kong, Taiwan,
              Japan, South Korea, Singapore,
              New Zealand, Malaysia, Philippines, Indonesia, Vietnam, Thailand,
              Cambodia, Myanmar, Bangladesh,
              Pakistan & Sri Lanka.</li>
        </ul>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
